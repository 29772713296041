<template>
    <div >
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-toolbar flat dense class="tc-title">
                <router-link to="student-publication" class="nav-arrow-left"><v-icon class="left-arrow" >mdi-arrow-left-bold</v-icon></router-link>
                <v-toolbar-title>Social Details</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <span style="width: 20px;"></span>
                <template>
                    <v-btn color="primary" dark class="logi mx-2" fab small @click="dialog_add=true"><v-icon>mdi-plus</v-icon></v-btn>
                    <router-link to="employability" class="nav-arrow-right"><v-icon class="right-arrow">mdi-forward</v-icon></router-link>
                </template>
        </v-toolbar>
        <!--<template>
            <v-toolbar flat color="white">
                <v-toolbar-title>Social Details</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <span style="width: 20px;"></span>
                <template>
                    <v-btn color="primary" dark class="mb-2" @click="dialog_add=true"><v-icon>mdi-plus</v-icon>Add Social</v-btn>
                </template>
            </v-toolbar>
        </template>
        <template>
            <v-data-table :headers="headers" :items="social_list" class="elevation-1" :search="search">
                <template v-slot:item.edit="{ item }">
                         <v-icon color="indigo" @click="editItem(item)">mdi-pencil-circle</v-icon>
                </template>
               <template v-slot:item.delete="{ item }">
                    <v-icon color="red" @click="delId(item)">mdi-delete-circle</v-icon>
                </template>
            </v-data-table>
        </template>-->
        <v-row>
            <v-col cols="12" sm='3' v-for="item in social_list" :key="item">
                <v-card style=" margin: 8px; height:100%">
                    <v-card-title class="c-title">
                        {{item.socialtype}}
                    </v-card-title>
                    <v-card-text>
                        <v-row style="margin: 0px !important; !important">
                            <v-col cols="6" sm="4" style="margin: 0px !important; padding: 0px !important">
                                <span class="lbl">Profile Link</span>
                            </v-col>
                            <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important">
                                : {{item.social}}
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                                <v-icon color="indigo" @click="editItem(item)">mdi-pencil-circle</v-icon>
                                <v-spacer></v-spacer>
                                <v-icon color="red" @click="delId(item)">mdi-delete-circle</v-icon>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <template>
            <v-row justify="center">
                <v-dialog v-model="dialog_add" persistent max-width="550px">
                    <v-card>
                        <v-card-title class="card-title">
                            Add Social Details
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="12">
                                        <label>Select Type<span class="required">*</span> :</label>
                                        <v-select :items="social_type" v-model="savedata.socialtype" item-text="name" item-value="id" required :rules="[v => !!v || 'required']"></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="12">
                                        <label>Profile Link<span class="required">*</span> :</label>
                                        <v-text-field placeholder="Enter Profile Link" v-model="savedata.link" :rules="[v => !!v || 'required']"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <!-- <v-btn color="blue darken-1" text @click="dialog_add= false">Close</v-btn>
                            <v-btn color="blue darken-1" text @click="save()">Save</v-btn> -->
                            <v-btn color="primary darken-1"  outlined text @click="dialog_add= false">Cancel</v-btn>
                            <v-btn color="primary darken-1" dark @click="save()">Save</v-btn>


                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>
        <template >
            <v-row justify="center">
                <v-dialog v-model="dialog_edit" persistent max-width="550px">
                    <v-card>
                        <v-card-title class="card-title">
                            Edit Social Details
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="12">
                                        <label>Select Type<span class="required">*</span> :</label>
                                        <v-select :items="social_type" v-model="editedItem.socialtype" item-text="name" item-value="id" required></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="12">
                                        <label>Profile Link<span class="required">*</span> :</label>
                                        <v-text-field placeholder="Enter Profile Link" v-model="editedItem.link"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <!-- <v-btn color="blue darken-1" text @click="dialog_edit= false">Close</v-btn>
                            <v-btn color="blue darken-1" text @click="edit()">Save</v-btn> -->
                             <v-btn color="primary darken-1"  outlined text @click="dialog_edit= false">Cancel</v-btn>
                            <v-btn color="primary darken-1" dark @click="edit()">Save</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>
        <template>
            <v-row justify="center">
                <v-dialog v-model="dialog_delete" persistent max-width="290">
                    <v-card>
                        <v-card-title class="del-card-title">Record Delete</v-card-title>
                        <input type="hidden" v-model="deleteItem.academic_id" />
                        <v-card-text>
                            Are you sure you want Delete this Record?
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1" text @click="dialog_delete = false">No</v-btn>
                            <v-btn color="red darken-1" text @click="deletedata()">Yes</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>
    </div>
</template>
<script>
    import axios from "axios";
    import StudentProfile from "./StudentProfile";
    import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";
    import * as lang from "vuejs-datepicker/src/locale";
    import datetime from "vuejs-datetimepicker";
    export default {
        components: {
            StudentProfile,
            Datepicker,
        },
        data: () => ({
            savedata: {
                socialtype: null,
                link: null,
            },
            editedItem: {
                socialtype: null,
                link: null,
                id: null,
            },
            publication_category_list: "",
            social_type: "",
            dialog_add: false,
            dialog_edit: false,
            dialog_delete: false,
            snackbar_msg: "",
            color: "",
            snackbar: false,
            search: "",
            social_list: [],

            headers: [{
                    text: 'Sr. No',
                    align: 'left',
                    sortable: false,
                    value: 'idd',
                },
                {
                    text: 'Profile Type',
                    align: 'left',
                    sortable: false,
                    value: 'socialtype',
                },
                {
                    text: 'Profile Link',
                    align: 'left',
                    sortable: false,
                    value: 'social',
                },
                {
                    text: 'Edit',
                    value: 'edit',
                    sortable: false
                },
                 {
                    text: 'Delete',
                    value: 'delete',
                    sortable: false
                },
               
            ],

            deleteItem: {
                lactivity_id: null,
            },
            editAllData: [],
        }),
        watch: {
            dialog(val) {
                val || this.close();
            },
        },
        mounted() {
            this.onLoad();
        },
        methods: {
            onLoad() {
                axios
                    .post("/LearnerData/learnerSocial")
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.social_list = res.data.social_list;
                            this.social_type = res.data.social_type;
                        } else {
                            this.showSnackbar("#b71c1c", "No Data");
                           // window.console.log(res.data.msg);
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                       // window.console.log(error);
                    })
                    .finally(() => {
                        // 'use strict';
                    });
            },
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
            editItem(item) {
                //alert("ID "+item.id)
                this.dialog_edit = true;
                this.editedItem.id = item.id;
                this.editedItem.link = item.social;
                this.editedItem.socialtype = item.socialtypeid;
            },
            delId(item) {
                this.dialog_delete = true;
                this.deleteItem.lactivity_id = item.id;
            },
            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },
            save() {
                if (this.savedata.socialtype == null) {
                    this.showSnackbar("#b71c1c", "Select Social Type..");
                } else if (this.savedata.link == null) {
                    this.showSnackbar("#b71c1c", "Enter Social Profile Link..");
                } else {
                    let formData = new FormData();
                    formData.append("link", this.savedata.link);
                    formData.append("type", this.savedata.socialtype);
                    this.dialog_add = false;
                    axios
                        .post("/LearnerData/saveSocial", formData)
                        .then((res) => {
                            if (res.data.msg == "200") {
                                this.onLoad();
                                this.showSnackbar("#4caf50", "Publication Added Successfully..."); // show snackbar on success
                                this.savedata.socialtype = null;
                                this.savedata.link = null;
                            } else if (res.data.msg == "Na") {
                                this.showSnackbar("#b71c1c", "Publication Already Present!!!");
                                this.savedata.socialtype = null;
                                this.savedata.link = null;
                            }
                        })
                        .catch((error) => {
                           // window.console.log(error);
                        });

                    this.close();
                }
            }, // end of Save()
            edit() {
                let formData = new FormData();
                formData.append("type", this.editedItem.socialtype);
                formData.append("link", this.editedItem.link);
                formData.append("id", this.editedItem.id);
                this.dialog_edit = false;
                axios
                    .post("/LearnerData/editSocial", formData)
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.dialog_edit = false;
                            this.showSnackbar("#4caf50", "Social profile Updated Successfully..."); // show snackbar on success
                            this.onLoad();
                        } else {
                            this.showSnackbar("#b71c1c", "Social profile Already Present!!!");
                        }
                    })
                    .catch((error) => {
                        //window.console.log(error);
                    });
                this.close();
            }, // end of edit()
            deletedata(item) {
                let formData = new FormData();
                formData.append("id", this.deleteItem.lactivity_id);
                this.dialog_delete = false;
                axios.post("/LearnerData/deletesocial", formData).then((res) => {
                    if (res.data.msg == "200") {
                        if ((this.load = true)) {
                            this.onLoad();
                            this.showSnackbar("#4caf50", " Record Delete Successfully...");
                            th;
                        }
                    } else {
                        this.showSnackbar("#b71c1c", "Record Not Delete...");
                    }
                });
            },
        },
    };
</script>
<style scoped>
.nav-arrow-left{
        margin-right: 5px;
        text-decoration: none;
        color: #FFF;
    }
    .nav-arrow-right{
        margin: 5px;
        text-decoration: none;
        color: rgb(3, 55, 167);
    }
    .left-arrow{
        color: #FFF;
    }
    .right-arrow{
        color: rgb(3, 55, 167);
    }

    .ec-title{
        background-image: linear-gradient(-100deg, #f4faba, #d64907);
        color: #FFF;
    }
    .c-title{
        background-image: linear-gradient(-90deg, #befcf7, #03948d);
        color: #FFF;
    }
    .tc-title{
        background-image: linear-gradient(-90deg, #FFF, #057996);
        color: #FFF;
        border-radius: 3px;
    }
    .logi{
        background-image: linear-gradient(-100deg, #8aa5f7, #063bdb);
        color: #FFF;
    }
    .card-title{
         background-image: linear-gradient(-90deg, #8edffd, #034c70);
        color: #FFF;
    }
    .del-card-title{
         background-image: linear-gradient(-90deg, #fde7e7, #ac0404);
        color: #FFF;
    }
    .lbl{
        color: #1705e6;
    }
    .example {
        margin-top: 0px;
        background: #ffff;
        border-bottom: 1px solid black;
        color: black;
    }
    .v-text-field {
        padding-top: 0px;
        margin-top: 0px;
    }
    .alink {
        text-decoration: none;
    }

    .v-icon-size {
        font-size: 20px;
    }

    .edit-avatar {
        transition: 0.3s;
        border: 1px solid #b0bec5;
    }

    .edit-avatar:hover {
        background-color: #b0bec5;
    }

    .edit-v-icon {
        padding-left: 6px;
        font-size: 15px;
    }

    .edit-v-icon:hover {
        color: white;
    }
    .required{
  color:red;
  font-size:14px;
  font-style:bold;
  }
</style>
